import {apiCall, paramsToString} from "utils/api";
import {NextPageContext} from "next";
import {isShopTabDomain} from "../utils/misc";

const HOST = `${process.env.NEXT_PUBLIC_API_HOST}/orders`;

export const makeOrder = async (data: any, buyNowParams: any) => {
  const params = {
    buyNowProductVariantId: '',
    buyNowQuantity: '',
  };
  if (buyNowParams) {
    params.buyNowProductVariantId = buyNowParams.buyNowProductVariantId;
    params.buyNowQuantity = buyNowParams.buyNowQuantity;
  }
  const res = await apiCall({
    method: 'post',
    url: `${HOST}?${buyNowParams ? paramsToString(buyNowParams) : ''}`,
    data,
  })
  return res.data;
}

export const getPaymentModes = async (ctx: NextPageContext, pincode: string) => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/paymentOptions?pincode=${pincode}`,
    ctx
  })
  return res.data;
}

export const getReturnReasons = async () => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/clout/returnReason`,
    headers: {
      'X-External-App-Name': isShopTabDomain() ? 'roposo' : 'shipstreak',
    }
  })
  return {returnReasons: res.data.listElements};
}

export const saveReversePickupImages = async (suborderId: number, formData: any) => {
  const res = await apiCall({
    method: 'post',
    url: `${HOST}/suborders/${suborderId}/reversePickupImages`,
    headers: {
      'X-External-App-Name': isShopTabDomain() ? 'roposo' : 'shipstreak',
    },
    data: formData,
  })
  return res.data;
}

export const raiseReturnRequest = async (suborderId: number, reason: string, quantity: number, numberOfImages: number, modeOfRefund: string) => {
  const res = await apiCall({
    method: 'post',
    url: `${HOST}/suborders/${suborderId}/reversePickup/web?modeOfRefund=${modeOfRefund}`,
    headers: {
      'X-External-App-Name': isShopTabDomain() ? 'roposo' : 'shipstreak',
    },
    data: {
      isCloutOrder: true,
      reason,
      quantity,
      numberOfImages,
      comment: "",
    },
  })
  return {returnRequestDetails: res.data};
}

export const rejectReturnRequest = async (suborderId: number, reason: string) => {
  const res = await apiCall({
    method: 'post',
    url: `${HOST}/clout/rejectReturn?suborderId=${suborderId}&reason=${reason}`,
    headers: {
      'X-External-App-Name': isShopTabDomain() ? 'roposo' : 'shipstreak',
    },
    data: {},
  })
  return {returnRequestDetails: res.data};
}
