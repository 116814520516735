import React from 'react';
import Image from "next/image";
import shipstreak from "./Shipstreak.svg";
import useDomainInfo from "hooks/determine-client-domain";

const Footer = () => {
  const {isShopTab, isShipstreak, hasWindow} = useDomainInfo();
  return <div className="absolute left-0 bottom-0 w-full">
    <div className={'w-full mb-6'}>
      {hasWindow && <div className="text-center">
        <a className="text-blue-600 text-xs font-normal underline leading-normal tracking-tight"
           rel="noreferrer"
           target="_blank"
           href={isShipstreak ? process.env.NEXT_PUBLIC_RETURNS_LINK_SHIPSTREAK_REFUND_POLICY : process.env.NEXT_PUBLIC_RETURNS_LINK_SHOPTAB_REFUND_POLICY}>
          Return & Refund Policy
        </a>
        <span className="text-blue-600 text-xs font-normal leading-normal tracking-tight"> | </span>
        <a className="text-blue-600 text-xs font-normal underline leading-normal tracking-tight"
           rel="noreferrer"
           target="_blank"
           href={isShipstreak ? process.env.NEXT_PUBLIC_RETURNS_LINK_SHIPSTREAK_PRIVACY_POLICY : process.env.NEXT_PUBLIC_RETURNS_LINK_SHOPTAB_PRIVACY_POLICY}>
          Privacy Policy
        </a>
      </div>}
      {hasWindow && isShopTab && <div className="text-center">
          <a className="text-blue-600 text-xs font-normal underline leading-normal tracking-tight"
             rel="noreferrer"
             target="_blank"
             href={process.env.NEXT_PUBLIC_RETURNS_LINK_SHOPTAB_TNC}>
            Terms & Conditions
          </a>
        </div>}
    </div>
    <div className={'flex justify-center w-full py-3 border-t border-zinc-100'}>
      <span className="text-neutral-400 text-xs font-normal leading-[14.40px] tracking-tight">© 2023 All Rights Reserved.</span>
      {isShipstreak && <>
        <span className="text-neutral-400 text-xs font-normal leading-[14.40px] tracking-tight">&nbsp;Powered by</span>
        <Image src={shipstreak}/>
      </>}
    </div>
  </div>;
};

export default Footer;