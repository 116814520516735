export const isMobileNumber = (value: string) => value && /^[23456789]\d{9}$/i.test(value.toString())

export const isValidString = (value: string) => value && value.trim().length > 0

export const isValidNumber = (value: string) => value && !isNaN(Number(value))

export const isValidAlphanumeric = (value: string) => value && /^[a-z0-9]+$/i.test(value.toString())

export const isValidAlphanumericOrderId = (value: string) => value && /^[#a-z0-9]+$/i.test(value.toString())

